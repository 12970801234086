import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Root = styled(Modal)`
  .modal-content {
    padding: 32px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    text-align: center;
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;
