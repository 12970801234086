import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Divider as Separator } from '@mui/material';

export const Root = styled.div`
  background-color: #f4f4f6;
  padding-top: 48px;
  padding-bottom: 48px;
`;

export const FooterContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;

export const Tagline = styled.span`
  color: #383942;
  font-weight: 700;
  font-size: 14px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: #383942;
`;

export const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Link = styled.a`
  color: #5e606e;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

export const Divider = styled(Separator)`
  && {
    width: 100%;
    margin: 48px 0;
    border: 1px solid lightgray;

    @media (max-width: 767px) {
      margin: 24px;
    }
  }
`;

export const BottomGroup = styled.div`
  display: flex;
  gap: 24px;
`;
