import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const Root = styled(Container)`
  margin-top: 80px;
  text-align: center;
  max-width: 900px;
`;

export const TagLine = styled.div`
  text-transform: uppercase;
  color: #5167f6;
  font-weight: 900;
  size: 12px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 64px;
  color: '#333333';


  @media (max-width: 767px) {
    font-size: 48px;
  }
`;

export const InfoText = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: #383942;
  
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const UploadContainer = styled.div`
  position: relative;
  background: #ffffff;

  box-shadow: 0px 8px 64px rgba(143, 143, 143, 0.16);
  border-radius: 24px;
  padding: 16px 24px;
  margin-bottom: -175px; // Ideally we want to calculate this value to be half the UploadArea's height
`;

export const UploadArea = styled.div`
  border: 1px dashed rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  height: 375px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
