import AmexIcon from '../assets/amex.svg';
import DiscoverIcon from '../assets/discover.svg';
import MastercardIcon from '../assets/mastercard.svg';
import VisaIcon from '../assets/visa.svg';
import CreditIcon from '../assets/creditcard4.svg';
import DebitCard from '../assets/debitcard4.svg';

export const calculateInterchangePercent = (fee_navigator_response) => {
  return (fee_navigator_response?.['Percent Pass-Through Fees']*100 * (fee_navigator_response?.['Interchange & downgrades'] / fee_navigator_response?.['Pass-Through Fees'])).toFixed(2)
};

export const calculatePassThroughPercent = (fee_navigator_response) => {
  return (fee_navigator_response?.['Percent Pass-Through Fees']*100).toFixed(2);
};

export const calculateDuesPercent = (fee_navigator_response) => {
  return (fee_navigator_response?.['Percent Pass-Through Fees']*100 * ((fee_navigator_response?.['Dues & Assessments']) / fee_navigator_response?.['Pass-Through Fees'])).toFixed(2);
};

export const calculateProcessingPercent = (fee_navigator_response) => {
  return (fee_navigator_response?.['Percent Processing Fees']*100).toFixed(2);
};

export const getBrandVolumeSplit = (fee_navigator_response) => {
  const data = [
    {
      name: 'Amex',
      labelIcon: AmexIcon,
      color: '#016FD0',
      value: fee_navigator_response['American Express amount'],
      type: 'Currency'
    },
    {
      name: 'Discover',
      labelIcon: DiscoverIcon,
      color: '#E55C20',
      value: fee_navigator_response['Discover Credit amount'] + fee_navigator_response['Discover Debit amount'],
      type: 'Currency'
    },
    {
      name: 'Mastercard',
      labelIcon: MastercardIcon,
      color: '#F79E1B',
      value: fee_navigator_response['Mastercard Credit amount'] + fee_navigator_response['Mastercard Debit amount'],
      type: 'Currency'
    },
    {
      name: 'Visa',
      labelIcon: VisaIcon,
      color: '#1A1F71',
      value: fee_navigator_response['Visa Credit amount'] + fee_navigator_response['Visa Debit amount'],
      type: 'Currency'
    },
  ];

  const output = {
    label: 'Transaction $ Volume by Card',
    data,
  };

  return output;
};

export const getBrandTransactionSplit = (fee_navigator_response) => {
  const data = [
    {
      name: 'Amex',
      labelIcon: AmexIcon,
      color: '#016FD0',
      value: fee_navigator_response['American Express nrTransactions'],
      txnFeeValue: `${(fee_navigator_response['American Express discRate'] * 100).toFixed(2)}% + ${fee_navigator_response['American Express Auth transactionFee']*100}¢`,
      volumeValue: fee_navigator_response['American Express amount'],
      type: 'Number'
    },
    {
      name: 'Discover',
      labelIcon: DiscoverIcon,
      color: '#E55C20',
      value: fee_navigator_response['Discover Credit nrTransactions'] + fee_navigator_response['Discover Debit nrTransactions'],
      txnFeeValue: `${(fee_navigator_response['Discover Credit discRate'] * 100).toFixed(2)}% + ${fee_navigator_response['Discover Auth transactionFee']*100}¢`,
      volumeValue: fee_navigator_response['Discover Credit amount'] + fee_navigator_response['Discover Debit amount'],
      type: 'Number'
    },
    {
      name: 'Mastercard',
      labelIcon: MastercardIcon,
      color: '#F79E1B',
      value: fee_navigator_response['Mastercard Credit nrTransactions'] + fee_navigator_response['Mastercard Debit nrTransactions'],
      txnFeeValue: `${(fee_navigator_response['Mastercard Credit discRate'] * 100).toFixed(2)}% + ${fee_navigator_response['Mastercard Auth transactionFee']*100}¢`,
      volumeValue: fee_navigator_response['Mastercard Credit amount'] + fee_navigator_response['Mastercard Debit amount'],
      type: 'Number'
    },
    {
      name: 'Visa',
      labelIcon: VisaIcon,
      color: '#1A1F71',
      value: fee_navigator_response['Visa Credit nrTransactions'] + fee_navigator_response['Visa Debit nrTransactions'],
      txnFeeValue: `${(fee_navigator_response['Visa Credit discRate'] * 100).toFixed(2)}% + ${fee_navigator_response['Visa Auth transactionFee']*100}¢`,
      volumeValue: fee_navigator_response['Visa Credit amount'] + fee_navigator_response['Visa Debit amount'],
      type: 'Number'
    },
  ];

  const output = {
    label: 'Transaction # Count by Card',
    data,
  };

  return output;
};

export const getCreditDebitSVolumeSplit = (fee_navigator_response) => {
  const data = [
    {
      name: 'Credit',
      labelIcon: CreditIcon,
      color: '#396C84',
      value: fee_navigator_response['American Express amount'] + fee_navigator_response['Discover Credit amount'] + fee_navigator_response['Mastercard Credit amount'] + fee_navigator_response['Visa Credit amount'],
      type: 'Currency'
    },
    {
      name: 'Debit',
      labelIcon: DebitCard,
      color: '#3E9A50',
      value: fee_navigator_response['Discover Debit amount'] + fee_navigator_response['Mastercard Debit amount'] + fee_navigator_response['Visa Debit amount'],
      type: 'Currency'
    },
  ];

  const output = {
    label: 'Transaction $ Volume Credit vs Debit',
    data,
  };

  return output;
};

export const getCreditDebitTransactionSplit = (fee_navigator_response) => {
  const data = [
    {
      name: 'Credit',
      labelIcon: CreditIcon,
      color: '#396C84',
      value: fee_navigator_response['American Express nrTransactions'] + fee_navigator_response['Discover Credit nrTransactions'] + fee_navigator_response['Mastercard Credit nrTransactions'] + fee_navigator_response['Visa Credit nrTransactions'],
      type: 'Number'
    },
    {
      name: 'Debit',
      labelIcon: DebitCard,
      color: '#3E9A50',
      value: fee_navigator_response['Discover Debit nrTransactions'] + fee_navigator_response['Mastercard Debit nrTransactions'] + fee_navigator_response['Visa Debit nrTransactions'],
      type: 'Number'
    },
  ];

  const output = {
    label: 'Transaction # Count Credit vs Debit',
    data,
  };

  return output;
};


// Question auth vs non-auth
export const createCardFeeTableRows = (fee_navigator_response) => {
  const rows = [
    {
      labelIcon: AmexIcon,
      name: 'Amex',
      value: `${(fee_navigator_response['American Express discRate'] * 100).toFixed(2)}% + ${fee_navigator_response['American Express Auth transactionFee']*100}¢`,
    },
    {
      labelIcon: DiscoverIcon,
      name: 'Discover',
      value: `${(fee_navigator_response['Discover Credit discRate'] * 100).toFixed(2)}% + ${fee_navigator_response['Discover Auth transactionFee']*100}¢`,
    },
    {
      labelIcon: MastercardIcon,
      name: 'Mastercard',
      value: `${(fee_navigator_response['Mastercard Credit discRate'] * 100).toFixed(2)}% + ${fee_navigator_response['Mastercard Auth transactionFee']*100}¢`,
    },
    {
      labelIcon: VisaIcon,
      name: 'Visa',
      value: `${(fee_navigator_response['Visa Credit discRate'] * 100).toFixed(2)}% + ${fee_navigator_response['Visa Auth transactionFee']*100}¢`,
    },
  ];

  return rows;
};

export const createAccountFeesTableRows = (fee_navigator_response) => {
  const fees = fee_navigator_response['Processor-Specific Fees'].filter(fee => fee.category === 'Service').map((fee) => ({
    name: fee.term,
    value: `$${fee.fee.toFixed(2)}`,
    numberValue: Number(fee.fee),
  }));
  fees.push({
    name: 'TOTAL',
    value: `$${fees.reduce((accumulator, fee) => accumulator + fee.numberValue, 0).toFixed(2)}`
  });
  return fees;
};