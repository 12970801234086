//@ts-nocheck
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, LabelList } from 'recharts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { isMobile } from 'react-device-detect';
import {
  calculateInterchangePercent,
  calculateDuesPercent,
  calculatePassThroughPercent,
  calculateProcessingPercent,
  getBrandVolumeSplit,
  getBrandTransactionSplit,
  getCreditDebitSVolumeSplit,
  getCreditDebitTransactionSplit,
  createCardFeeTableRows,
  createAccountFeesTableRows,
} from '../../utils/report';
import { Root, Tile, Title, TileTitle } from './Report.styled';
import Navbar from '../../components/Navbar/Navbar';
import { getReportAPI } from '../../api/report';

function closestLowerHalf(num) {
  // Truncate the number to get the whole part
  var wholeNumber = Math.floor(num);
  
  // Calculate the decimal part of the number
  var decimal = num - wholeNumber;
  
  // Check if the decimal part is greater than or equal to 0.5
  if (decimal >= 0.5) {
    return wholeNumber + 0.5;
  } else {
    return wholeNumber;
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  console.log('payload', payload);
  if (active) {
    return (
      <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.95)', padding: '20px', border: '1px solid #DEDFE3', borderRadius: '12px' }}>
        <p style={{ fontWeight: '500', fontSize: '14px', color: 'rgb(81, 103, 246)', margin: '0px', padding: '0px' }}>{`Processing Fees: ${payload[1].value}%`}</p>
        <p style={{ fontSize: '12px' }}>Fees charged by the payment processor for handling and facilitating the transaction.</p>
        <p style={{ fontWeight: '500', fontSize: '14px', color: 'red', margin: '0px', padding: '0px' }}>{`Pass-Through Fees: ${payload[0].value}%`}</p>
        <p style={{ fontSize: '12px' }}>Fees directly charged by card networks or banks, passed through without any markup.</p>
      </div>
    );
  }

  return null;
}

const Report = () => {
  const { _report = '' } = useParams();
  const navigate = useNavigate();
  const [report, setReport] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  const getReport = async (_report: String, tryCount: Number) => {
    try {
      const reportAPIResponse = await getReportAPI(_report);
      setReport(reportAPIResponse.data);
      setIsLoading(false);
    } catch (e) {
      if (tryCount < 3) {
        setTimeout(() => {
          getReport(_report, tryCount + 1)
        }, 1500)
      } else {
        alert('Report not available');
        navigate('/');
      }
    }
  }

  useEffect(() => {
    if (!_report) {
      navigate('/');
    }
    getReport(_report);
  }, [_report, navigate]);

  if (isLoading) {
    return (
      <div className='mb-5'>
        <Navbar />
        <Root style={{ alignItems: 'center' }}>
          <CircularProgress />
        </Root>
      </div>
    )
  }

  const { fee_navigator_response } = report;

  const passThruFees = Number(calculatePassThroughPercent(fee_navigator_response));
  const processingFees = Number(calculateProcessingPercent(fee_navigator_response));
  const effectiveRate = Number(fee_navigator_response?.['Effective Rate']) * 100;

  const feeBreakdownData = {
    name: 'Fee Type',
    // 'Interchange': calculateInterchangePercent(fee_navigator_response),
    'Pass-Through Fees': passThruFees,
    // 'Dues & Assessments': calculateDuesPercent(fee_navigator_response),
    'Processing Fees': processingFees,
  };

  let domainMin = 0;
  if ((processingFees < .5)) {
    domainMin = closestLowerHalf(effectiveRate) - 1;
  }

  let tickValues = [];

  if (domainMin) {
    for (let i = domainMin; i <= passThruFees + processingFees + .5; i += 0.5) {
      tickValues.push(i);
    }
  } else {
    for (let i = domainMin; i <= passThruFees + processingFees + 1; i += 0.5) {
      tickValues.push(i);
    }
  }

  console.log('tickValues', tickValues, domainMin);

  return (
    <div className='mb-5'>
      <Navbar />
      <Root>
        <Title>
          Report
        </Title>
        <div className='mt-4'>
          <Tile>
            <TileTitle className='mb-4'>
              Transaction Fee Overview
            </TileTitle>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <div className='chart'>
                <BarChart
                  style={{
                    overflow: 'visible'
                  }}
                  width={isMobile ? 375 : 500}
                  height={300}
                  data={[feeBreakdownData]}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <Tooltip content={<CustomTooltip />} />
                  <CartesianGrid vertical={false} strokeDasharray="3" />
                  <XAxis dataKey="name" />
                  <YAxis allowDataOverflow={true} padding={{ top: 30 }} name="Percent" tickFormatter={(tick) => tick.toFixed(2)} domain={[domainMin ? 1 : 0, tickValues[tickValues.length-1]]} ticks={tickValues} />                  <Tooltip formatter={(value, name, props) => (`${value}%`)} />
                  <Legend />
                  <Bar dataKey='Pass-Through Fees' stackId="a" fill="red">
                    <LabelList dataKey='Pass-Through Fees' offset={5} content={(props) => renderCustomizedLabel(props, false)} position="right" />
                  </Bar>
                  <Bar dataKey='Processing Fees' stackId="a" fill="rgb(81, 103, 246)">
                    <LabelList dataKey='Processing Fees' offset={5} content={(props) => renderCustomizedLabel(props, true)} position="right" />
                  </Bar>
                </BarChart>
              </div>
              <div className='mt-2'>
                <div style={{ fontSize: '18px', border: '1px solid #DEDFE3', borderRadius: '10px', padding: '10px' }}>
                  <div style={{ color: 'rgb(81, 103, 246)', fontSize: '28px', fontWeight: '600' }}>
                    ${report?.fee_navigator_response?.['Processing Fees'].toLocaleString(undefined, { maximumFractionDigits: 2 })} <span style={{ fontSize: '14px', fontWeight: '500' }}><i>({calculateProcessingPercent(fee_navigator_response)}% markup per transaction)</i></span>
                  </div>
                  <div style={{ color: 'black', width: '100%', borderBottom: '1px solid #DEDFE3', paddingBottom: '5px' }}>
                    in monthly processing fees
                  </div>
                  <div style={{ color: '#5167F6', fontWeight: 400, fontSize: '12px', fontStyle: 'italic', marginTop: '10px' }}>
                    <span style={{ fontStyle: 'normal', fontWeight: 700 }}>ROMBIS TIP:</span> Your processing fees can be negotiated lower.
                  </div>
                </div>

              </div>
            </div>
            <div className='mt-4' style={{ fontSize: '18px', width: !isMobile ? '90%' : '100%', margin: 'auto', display: 'flex', gap: '10px', flexDirection: 'column', border: '1px solid #DEDFE3', borderRadius: '12px', padding: '20px', maxWidth: '600px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}> 
                <div>
                  Transaction Volume ($)
                </div>
                <div style={{ fontWeight: 700 }}>
                  ${fee_navigator_response?.['Calculated Total Volume'].toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  Total Fees ($)
                </div>
                <div style={{ fontWeight: 700 }}>
                  ${fee_navigator_response?.['Total Fees'].toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #DEDFE3', paddingTop: '12px' }}>
                <div>
                  Effective Rate (%)
                </div>
                <div style={{ fontWeight: 700 }}>
                  {(fee_navigator_response?.['Effective Rate'] * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%
                </div>
              </div>
            </div>
          </Tile>
          <div className='mt-4'>
            <Tile>
              <TileTitle className='mb-4'>
                Card Fees
              </TileTitle>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexFlow: 'wrap' }}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexFlow: 'wrap', width: '100%' }}>
                  <CardVolumeChart reportData={fee_navigator_response} fee_navigator_response={fee_navigator_response} />
                </div>
              </div>
              {/* <div className='mt-4'>
                <div style={{ border: '1px solid #DEDFE3', borderRadius: '12px', overflow: 'hidden', maxWidth: '600px', margin: 'auto' }}>
                  <div style={{ fontSize: '18px', fontWeight: '500', width: '100%', padding: '10px', background: '#eceefe' }}>
                    Transaction Fees by Card
                  </div>
                  <div>
                    <CardFeeTable reportData={fee_navigator_response} />
                  </div>
                </div>
              </div> */}
            </Tile>
          </div>
          <div className='mt-4'>
            <Tile>
              <TileTitle className='mb-4'>
                Account Fees
              </TileTitle>
              <div className='mt-4'>
                <div style={{ border: '1px solid #DEDFE3', borderRadius: '12px', overflow: 'hidden', margin: 'auto' }}>
                  <div style={{ fontSize: '18px', fontWeight: '500', width: '100%', padding: '10px', background: '#eceefe' }}>
                    Account Monthly Service Fees
                  </div>
                  <div>
                    <AccountFeesTable reportData={fee_navigator_response} />
                  </div>
                </div>
              </div>
            </Tile>
          </div>
        </div>
      </Root>
    </div>
  );
}

const renderCustomizedLabel = (props, negotiable) => {
  console.log('negotiable', negotiable);
  const { x, y, width, height, value } = props;
  return (
    <>
      <g>
        <text font-size={isMobile ? 14 : 18} font-weight={negotiable ? "bold" : ""} x={negotiable ? 17 + x + width / 6 : 5 + x + width / 6} y={y + height / 2} fill={'white'} dominantBaseline="middle">{negotiable ? `Negotiable (${value}%)` : `Non-Negotiable (${value}%)`}</text>
      </g>
      {/* {!isMobile &&
        <g>
          <text font-weight="bold" x={x + width + 10} y={y + height / 2} fill={negotiable ? 'rgb(81, 103, 246)' : 'red'} dominantBaseline="middle">{negotiable ? 'Processing Fees' : 'Pass-Through Fees'}</text>
        </g>
      } */}
    </>
  );
};

const CardTransactionChart = ({ reportData }) => {
  const { data, label } = getBrandTransactionSplit(reportData);
  return (
    <TransactionCountTable rows={data} label={label} />
  )
};

const CardVolumeChart = ({ reportData, fee_navigator_response }) => {
  const { data, label } = getBrandVolumeSplit(reportData);
  return (
    <div className="rombis-pie" style={{ border: '1px solid #DEDFE3', borderRadius: '12px', overflow: 'hidden', flex: 1, minWidth: '300px' }}>
      <RombisPieChart data={data} label={label} />
      <CardTransactionChart reportData={fee_navigator_response} />
    </div>
  )
};

const CreditDebitVolumeChart = ({ reportData }) => {
  const { data, label } = getCreditDebitSVolumeSplit(reportData);
  return (
    <RombisPieChart data={data} label={label} />
  )
};

const CreditDebitTransactionChart = ({ reportData }) => {
  const { data, label } = getCreditDebitTransactionSplit(reportData);
  return (
    <TransactionCountTable rows={data} label={label} />
  )
};

const RombisPieChart = ({ data, label }) => {
  const renderLabel = (cell: Object) => {
    const { cx, cy, midAngle, outerRadius, labelIcon, value, type } = cell;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 30;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    let renderValue;
    switch (type) {
      case 'Currency':
        renderValue = `$${value.toLocaleString(undefined, { maximumFractionDigits: 2 })}`
        break;
      default:
        renderValue = value;
    };
    return (
      <g>
        <image
          x={x - 12}
          y={y - 12}
          width={50}
          height={34}
          xlinkHref={labelIcon}
        />
        {/* {!isMobile &&
          <text
            x={x > cx ? x + 32 : x - 18}
            y={y}
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
            style={{ fontSize: '18px' }}
          >
            {renderValue}
          </text>
        } */}
        <LabelList dataKey="name" content={false} />
      </g>
    );
  }

  return (
    <div>
      <PieChart width={isMobile? 350 : 500} height={isMobile? 325 : 400}>
        <Pie
          dataKey="value"
          data={data}
          labelLine={true}
          label={renderLabel}
          innerRadius={isMobile? 35 : 80}
          outerRadius={isMobile ? 80 : 130}
          paddingAngle={8}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};

const TransactionCountTable = ({ rows, label, showLabel }) => {
  return (
    <div style={{ borderTop: '1px solid #DEDFE3', borderRadius: '12px', overflow: 'hidden', height: 'fit-content', flex: 1, minWidth: '300px' }}>
      {showLabel &&
        <div style={{ fontSize: '18px', fontWeight: '500', width: '100%', padding: '10px', background: '#eceefe' }}>
          {label}
        </div>
     }
      <TableContainer component={Paper}>
        <Table aria-label="customized table" style={{ fontSize: '18px' }}>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: 700 }}>Card Brand</TableCell>
              <TableCell align="center" style={{ fontWeight: 700 }}>Count</TableCell>
              <TableCell align="center" style={{ fontWeight: 700 }}>Volume</TableCell>
              <TableCell align="center" style={{ fontWeight: 700 }}>Txn Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.name} style={{ background: index % 2 === 0 ? 'white' : 'rgb(222, 223, 227, .25)' }}>
                <TableCell component="th" scope="row" style={{ minWidth: '200px' }}>
                  <img width={42} height={26} src={row.labelIcon} alt='card' /> <span>{row.name}</span>
                </TableCell>
                <TableCell align="center">{row.value}</TableCell>
                <TableCell align="center" style={{ minWidth: '150px' }}>${row.volumeValue}</TableCell>
                <TableCell align="center" style={{ minWidth: '150px' }}>{row.txnFeeValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
};

const CardFeeTable = ({ reportData }) => {
  const rows = createCardFeeTableRows(reportData);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table" style={{ fontSize: '18px' }}>
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: 700 }}>Card Brand</TableCell>
            <TableCell align="right">Transaction Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.name} style={{ background: index % 2 === 0 ? 'white' : 'rgb(222, 223, 227, .25)' }}>
              <TableCell component="th" scope="row">
                <img width={42} height={26} src={row.labelIcon} alt='card' /> <span>{row.name}</span>
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 700 }}>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

const AccountFeesTable = ({ reportData }) => {
  const rows = createAccountFeesTableRows(reportData);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table" style={{ fontSize: '18px' }}>
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: 700 }}>Name</TableCell>
            <TableCell align="right">Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.name} style={{ background: index % 2 === 0 ? 'white' : 'rgb(222, 223, 227, .25)' }}>
              <TableCell component="th" scope="row">
                <span>{row.name}</span>
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 700 }}>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default Report;