import { FunctionComponent, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ClipLoader } from 'react-spinners';
import { uploadAPI } from '../../api/upload';
import { updateReportAPI } from '../../api/report';
import ErrorModal from './ErrorModal';
import {
  StandardButton,
  Root,
  Text,
  FileBox,
  SuccessIcon,
  SuccessText,
} from './UploadBox.styled';
import BrowseIcon from '../../assets/browse.svg';
import CheckMark from '../../assets/green-checkmark.svg';
import FileIcon from '../../assets/file.svg';
import LargeBrowse from '../../assets/large-browse.svg';
import CancelIcon from '../../assets/cancel.svg';
import InfoFormModal from './InfoModal/InfoFormModal';

const UploadBox: FunctionComponent = () => {
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [generateReportLoading, setGenerateReportLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [rateLimitError, setRateLimitError] = useState<boolean>(false);
  const [reportCreated, setReportCreated] = useState<boolean>(false);
  const [reportId, setReportId] = useState<String | null>(null);
  const [requiresManualReview, setRequiresManualReview] = useState<boolean>(false);
  //const [checkoutUrl, setCheckoutUrl] = useState<String>('');

  let response: any = null;

  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the accepted files, e.g. upload to server
    //onUpload(acceptedFiles);
    const file = acceptedFiles[0];
    setPdfFile(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const cancelUpload = () => {
    setPdfFile(null);
  };

  // This function is run after user submits their information form
  // We attempt to update their report document with their info and then 
  // send them to their report page
  const handleFormSubmission = async (formData: any) => {
    // Hit our PUT report endpoint 
    const { email, name = '', phoneNumber = '' } = formData
    try {
      //
      if (!!reportId) response = await updateReportAPI(reportId, { email, name, phone: phoneNumber })
      // If for some reason a user has gotten to the form modal without a report created yet open the error modal
      else {
        setErrorModalOpen(true)
      }

      window.location.href = `/report/${reportId}`;

    } catch (error: any) {
      console.log('error:', error);
      setReportCreated(false);
      if (error?.response?.data?.errorCode === 'MRR') {
        setRequiresManualReview(true);
      }
      // Updating the report with the users information has failed
      // TODO: consider passing error messages to the modal since now we have multiple error scenarios
      setErrorModalOpen(true)
    }
  };

  const createReport = async () => {
    setGenerateReportLoading(true);
    setRequiresManualReview(false);
    setRateLimitError(false);
    try {
      const formData = new FormData();
      formData.append('file', pdfFile as Blob);
      response = await uploadAPI(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setReportId(response.data._report);

      // setCheckoutUrl(response.data.url);
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.errorCode === 'MRR') {
        setRequiresManualReview(true);
      } else if (error.response.status === 429) {
        setRateLimitError(true);
      }
      setErrorModalOpen(true);
    }
    setGenerateReportLoading(false);

    // Report successfully created
    if (response.status === 200) {
      setReportCreated(true);
    }
  };

  if (reportCreated)
    return (
      <Root>
        <SuccessIcon src={CheckMark} />
        <SuccessText>Successfully created analysis</SuccessText>
        <Text className='mt-4'>Fill out information to access your FREE analysis</Text>
        <StandardButton variant='contained' className='mt-2' onClick={() => setInfoModalOpen(true)}>Next</StandardButton>
        <InfoFormModal open={infoModalOpen} onClose={() => setInfoModalOpen(false)} handleSubmit={handleFormSubmission} />
      </Root>
    );

  if (pdfFile)
    return (
      <Root>
        {generateReportLoading ? (
          <Text>It may take up to a minute to generate your report...</Text>
        ) : (
          <SuccessText>File uploaded and ready</SuccessText>
        )}

        <FileBox className='mt-4 mb-1'>
          <img src={FileIcon} alt='' style={{ padding: '1px 6px' }} />
          <Text>{pdfFile.name}</Text>
          <button onClick={cancelUpload}>
            <img src={CancelIcon} alt='' />
          </button>
        </FileBox>
        <StandardButton
          onClick={createReport}
          className='mt-4'
          variant='contained'
        >
          {generateReportLoading ? (
            <ClipLoader color="white" loading={true} />
          ) : (
            'Create Report'
          )}
        </StandardButton>
        <ErrorModal
          open={errorModalOpen}
          onClose={() => {
            cancelUpload();
            setErrorModalOpen(false);
          }}
          rateLimitError={rateLimitError}
          requiresManualReview={requiresManualReview}
        />
      </Root>
    );

  return (
    <Root
      {...getRootProps()}
      className={`${isDragActive ? 'boxdrop-active' : ''}`}
    >
      <img src={LargeBrowse} alt='' height={118} className='mb-4' />
      <input {...getInputProps()} accept='.pdf' />
      {isDragActive ? (
        <Text>Drop the PDF file here ...</Text>
      ) : (
        <Text>Drag and drop a PDF file here or browse to select a file</Text>
      )}

      <StandardButton
        className='mt-3'
        variant='contained'
        startIcon={<img src={BrowseIcon} alt='' />}
      >
        Browse
      </StandardButton>
      <ErrorModal 
        open={errorModalOpen} 
        onClose={() => setErrorModalOpen(false)}
        requiresManualReview={requiresManualReview}
        rateLimitError={rateLimitError}
      />
    </Root>
  );
};

export default UploadBox;
