import { FunctionComponent } from 'react';
import {
  Root,
  TagLine,
  Title,
  InfoText,
  UploadContainer,
  UploadArea,
} from './Hero.styled';
import UploadBox from '../UploadBox/UploadBox';

const Hero: FunctionComponent = () => (
  <Root>
    <TagLine className='mb-3'>Get Started</TagLine>
    <div className='mb-4'><Title>Upload Statement</Title></div>
    <InfoText className='mb-5'>
      Make informed business decisions by utilizing our merchant statement
      analysis tool. Powered by AI, Rombis will shed light on your, normally
      complicated, merchant statement making it easier to understand.
    </InfoText>
    <UploadContainer>
      <UploadArea>
        <UploadBox />
      </UploadArea>
    </UploadContainer>
  </Root>
);

export default Hero;
