import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopSection = styled.div`
  background-color: #5167f6;
  height: 650px;
`;

export const TopContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BottomSection = styled.div`
  height: 330px;
`;

export const CheckImage = styled.img`
  margin-top: 180px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 64px;
  color: white;
`;
