import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';



export const StyledLink = styled(Link)`
  color: #160042;
  letter-spacing: -.01em;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50px;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  transition: all .35s;

  &:hover {
    color: #160042;
    background-color: #f3f5fb;
  }

  @media (max-width: 767px) {
    gap: 4px;
    padding: 8px 16px;
  }

`;

export const NavLogo = styled.img`
  width: 140px;
  @media (max-width: 767px) {
    width: 100px;
  }
`
export const Toggler = styled(Navbar.Toggle)`
  color: #393b6a;
  border: 1px solid #f3f5fb;
  border-radius: 8px;
  padding: 10px;
`

export const CollapsibleNav = styled(Navbar.Collapse)`
  justify-content: right;
`