import { FunctionComponent } from 'react';
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close';
import { Root } from '../ErrorModal.styled';
import { Text } from '../UploadBox.styled';
import UserForm from './UserForm';


interface InfoFormModalProps {
  open: boolean;
  onClose: () => void;
  handleSubmit: any
}

const InfoFormModal: FunctionComponent<InfoFormModalProps> = ({ open, onClose, handleSubmit }) => {
  return (
    <Root show={open}>
      <CloseDiv onClick={onClose}><CloseIcon /></CloseDiv>
      <div style={{ padding: '12px' }}>
        <Text style={{ fontSize: '24px' }}>Submit your information to receive your report</Text>
      </div>

      <UserForm onSubmit={handleSubmit} />

    </Root>
  );
};

const CloseDiv = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 16px;
  :hover {
    background-color: lightgray;
    cursor: pointer;
  }

`

export default InfoFormModal;
