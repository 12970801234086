import { Button } from '@mui/material';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 16px;
`;

export const Text = styled.span`
  color: #5167f6 !important;
  font-weight: 500;
  font-size: 16px;
`;

export const FileBox = styled.div`
  background-color: rgba(81, 103, 246, 0.08);
  display: flex;
  padding: 16px;
  border-radius: 99px;
  gap: 12px;
`;

export const StandardButton = styled(Button)`
  && {
    color: white;
    background-color: #5167f6;
    border-radius: 99px;
    font-weight: 900;
    font-size: 16px;
    padding: 12px 40px;
    width: fit-content;
    z-index: 100;
  }
`;

export const SuccessIcon = styled.img`
  border-radius: 100px;
`;

export const SuccessText = styled.span`
  color: #7ec897;
  font-size: 26px;
  font-weight: 500;
  margin-top: 20px;
`;
