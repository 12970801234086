import styled from 'styled-components';
import { Button } from '@mui/material';

export const Root = styled.div`
  display: flex;
  flex-direction: column;

  height: 330px;
  box-shadow: 0px 8px 64px rgba(143, 143, 143, 0.16);
  border-radius: 24px;
  background-color: white;
  margin-bottom: -80px;
  padding: 40px 60px;

  @media (max-width: 767px) {
    padding: 24px 24px;
  }
`;

export const Title = styled.div`
  align-self: center;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
`;

export const Description = styled.div`
  align-self: center;
  text-align: center;
  max-width: 65%;
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const StandardButton = styled(Button)`
  && {
    align-self: center;
    color: white;
    background-color: #5167f6;
    border-radius: 99px;
    font-weight: 900;
    font-size: 16px;
    padding: 12px 40px;
    width: fit-content;
    gap: 8px;
  }
`;
