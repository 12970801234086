import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel } from '@mui/material'
import { StandardButton } from '../UploadBox.styled';

interface UserFormProps {
  onSubmit: (data: FormData) => void;
}

interface FormData {
  email: string;
  phoneNumber?: string;
  name?: string;
  isAgreed: boolean;
}

const UserForm: React.FC<UserFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<FormData>({
    email: '',
    phoneNumber: '',
    name: '',
    isAgreed: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      isAgreed: checked,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.isAgreed) {
      onSubmit(formData);
    } else {
      alert('Please agree to the terms before submitting.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Email"
        type="email"
        name="email"
        required
        value={formData.email}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Phone Number"
        type="tel"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Name"
        type="text"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <div className='mt-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormControlLabel
          control={<Checkbox checked={formData.isAgreed} onChange={handleCheckboxChange} color='primary' />}
          label={<p className='mb-0'>I agree to the <a href="https://rombis.ai/terms-of-use" target='_blank'>Terms of Use</a></p>}
        />
        <StandardButton type="submit" variant="contained" color="primary">
          Submit
        </StandardButton>
      </div>
    </form>
  );
};

export default UserForm;
