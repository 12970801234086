import { FunctionComponent } from 'react';
import { Root, Title, Description, StandardButton } from './DownloadBox.styled';
import UploadIcon from '../../../assets/browse.svg';

interface DownloadBoxProps {
  className?: string;
}

const DownloadBox: FunctionComponent<DownloadBoxProps> = ({ className }) => (
  <Root className={className}>
    <Title>Download your report</Title>
    <Description className='my-4'>
      Your report is ready. You can download it below or check you email for
      further details
    </Description>
    <StandardButton className='mt-1' variant='contained'>
      {/*  TODO: Change img to download instead of upload icon */}
      <img src={UploadIcon} alt='' />
      Download PDF
    </StandardButton>
  </Root>
);

export default DownloadBox;
