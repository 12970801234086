import { FunctionComponent } from 'react';
import {
  Root,
  FooterContainer,
  Group,
  Tagline,
  Title,
  LinkGroup,
  Link,
  Divider,
  BottomGroup,
} from './Footer.styled';
import Logo from '../../assets/new-logo.png';

const Footer: FunctionComponent = () => (
  <Root>
    <FooterContainer>
      <Group>
        <img src={Logo} width={72} alt='' />
        <Tagline>AI Powered Merchant Statement Analysis </Tagline>
      </Group>
    </FooterContainer>

    <FooterContainer>
      <div style={{ width: '100%' }}>
        <Divider />
        <BottomGroup>
          <Link
            href='https://rombis.ai/privacy-policy'
            target='_blank'
          >
            Privacy Policy
          </Link>
          <Link
            href='https://rombis.ai/terms-of-use'
            target='_blank'
          >
            Terms of Use
          </Link>
        </BottomGroup>
      </div>
    </FooterContainer>
  </Root>
);

export default Footer;
