import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import {
  StyledLink,
  NavLogo,
  Toggler,
  CollapsibleNav
} from './Navbar.styled';
import Logo from '../../assets/new-logo.png';

const { REACT_APP_WEBFLOW_URI } = process.env;

const navLinks = [
  {
    title: 'How it works',
    destination: `${REACT_APP_WEBFLOW_URI}#How-it-works`,
  },
  {
    title: 'Pricing',
    destination: `${REACT_APP_WEBFLOW_URI}#Pricing`
  },
  {
    title: 'FAQ',
    destination: `${REACT_APP_WEBFLOW_URI}#FAQ`,
  },
  {
    title: 'Contact Us',
    destination: `${REACT_APP_WEBFLOW_URI}#Contact-us`,
  },
];

const Navigation: FunctionComponent = () => (
  <Container>
    <Navbar collapseOnSelect expand="sm" bg="white">
      <Navbar.Brand>
        <Link to={`${REACT_APP_WEBFLOW_URI}`}>
          <NavLogo src={Logo}
            alt="Rombis.ai"
            className="d-inline-block align-top"
          />
        </Link>
      </Navbar.Brand>
      <Toggler aria-controls="responsive-navbar-nav" />
      <CollapsibleNav id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {navLinks.map((navLink) => (
            <StyledLink to={navLink.destination}>{navLink.title}</StyledLink>
          ))}
        </Nav>
      </CollapsibleNav>
    </Navbar>
  </Container>
);

export default Navigation;
