import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const Root = styled.div`
  background-color: #5167f6;
  padding-top: 175px;
`;

export const PricingContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  gap: 24px;
  flex-direction: column;
  text-align: center;
`;

export const Title = styled.span`
  color: #ffffff;
  font-weight: 700;
  font-size: 48px;

  @media (max-width: 767px) {
    font-size: 36px;
  }
`;

export const Description = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  max-width: 700px;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

