import { FunctionComponent } from 'react';
import {
  BottomSection,
  Root,
  TopSection,
  CheckImage,
  Title,
  TopContainer,
} from './Success.styled';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import DownloadBox from './DownloadBox/DownloadBox';
import Checkmark from '../../assets/success.svg';

const SuccessPage: FunctionComponent = () => {
  return (
    <Root>
      <Navbar />
      <TopSection>
        <TopContainer>
          <CheckImage src={Checkmark} alt='' />
          <Title>Success</Title>
          <DownloadBox className='mt-5' />
        </TopContainer>
      </TopSection>
      <BottomSection />
      <Footer />
    </Root>
  );
};

export default SuccessPage;
