import { FunctionComponent } from 'react';
import {
  Root,
  PricingContainer,
  Title,
  Description,
} from './Pricing.styled';

const Pricing: FunctionComponent = () => (
  <Root>
    <PricingContainer id='pricing'>
      <Title> Free Powerful Analysis</Title>
      <Description>
        Take control of your payment processing costs and supercharge your financial decision-making. Join thousands of satisfied businesses who have transformed the way they manage their merchant statements.
      </Description>
    </PricingContainer>
  </Root >
);

export default Pricing;
