import { FunctionComponent } from 'react';
import { Root } from './ErrorModal.styled';
import { StandardButton } from './UploadBox.styled';

const { REACT_APP_SUPPORT_EMAIL } = process.env;

interface ErrorModalProps {
  open: boolean;
  onClose: () => void;
  requiresManualReview: boolean;
  rateLimitError: boolean;
}

const ErrorModal: FunctionComponent<ErrorModalProps> = ({ open, onClose, requiresManualReview, rateLimitError }) => {
  let headerText = 'Error analyzing your merchant statement';
  if (requiresManualReview) headerText = 'Your statement requires manual review';
  if (rateLimitError) headerText = "You've exceeded your daily limit for analyses.";
  let bodyText = `Contact our support team about the issue at ${REACT_APP_SUPPORT_EMAIL}`;
  if (requiresManualReview) bodyText = 'AI’s output is only as good as its input. If the PDF upload is a scanned or photographed copy of poor quality, or certain information is missing, it may require manual review. A representative of our team will contact you when the review is complete.';
  if (rateLimitError) bodyText = 'Upgrade your account to access unlimited reports or wait until tomorrow.';
  return (
    <Root show={open}>
      <h3>{headerText}</h3>
      <p>{bodyText}</p>
      <StandardButton onClick={onClose} variant='contained'>
        Close
      </StandardButton>
    </Root>
  );
};

export default ErrorModal;
